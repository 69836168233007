import {useRequest} from './RequestContext';
import {Moment} from "moment/moment";

const api = {
    useLogin: () => {
        const {request} = useRequest();
        const login = (email: string, password: string) => request('/auth/login', 'POST', {email, password});
        return {login};
    },
    useCreateSimulation: () => {
        const {request} = useRequest();
        const createSimulation = (data:object) => request('/simulation', 'POST', data);
        return {createSimulation};
    },
    useGetQuotations: () => {
        const {request} = useRequest();
        const getQuotations = (from:number, to: number, interval:string) => request('/quotation', 'GET', null, {
            from: from,
            to: to,
            interval: interval
        });
        return {getQuotations};
    },
    useGetSimulations: () => {
        const {request} = useRequest();
        const getSimulations = () => request('/simulation', 'GET');
        return {getSimulations};
    },
    useRestartSimulation: () => {
        const {request} = useRequest();
        const restartSimulation = (id:string, data:object) => request(`/simulation/${id}/restart`, 'POST', data);
        return {restartSimulation};
    },
    useGetSimulation: () => {
        const {request} = useRequest();
        const getSimulation = (id: string) => request(`/simulation/${id}`, 'GET');
        const getTriggers = (id: string, params: object|null) => request(`/simulation/${id}/trigger`, 'GET', null, params);
        const getOrders = (id: string) => request(`/simulation/${id}/order`, 'GET');
        const deleteSimulation = (id: string) => request(`/simulation/${id}`, 'DELETE');
        return {getSimulation, getTriggers, deleteSimulation, getOrders};
    },
}

export default api;