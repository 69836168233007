import { ProgressBar } from 'react-bootstrap';
import React from "react";

interface SimulationProgressProps {
    progress: number;
}

const SimulationProgress: React.FC<SimulationProgressProps> = ({ progress }) => {
    return (
        <ProgressBar className="flex-grow-1 ml-2" animated={true} now={progress} label={`${progress}%`} />
    );
};

export default SimulationProgress;
