import React, {useEffect, useRef, useState} from 'react';
import './SimulationView.css';
import Layout from './../Layout'
import {Button, Card, Col, Container, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import api from "../Api";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {Moment} from "moment/moment";
import * as moment from "moment";
import {
    createChart,
    CrosshairMode,
    ISeriesApi,
    SeriesMarker,
    SeriesMarkerPosition,
    SeriesMarkerShape,
    Time,
    UTCTimestamp
} from "lightweight-charts";
import MetricsInfo from "./MetricsInfo";
import RestartModal from "./RestartModal";
import {io} from "socket.io-client";
import SimulationProgress from "../SimulationList/SimulationProgress";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;

export interface Simulation {
    id: string,
    status: string,
    startSimulationDate: Moment,
    endSimulationDate: Moment,
    symbol: string,
    comment?: string,
    startBalance: number,
    endBalance?: number,
    intervalSMAFrom: number,
    intervalSMATo: number,
    SMAInterval: number,
    RSIInterval: number,
    RSIDevergenceWindow: number,
    MACDShortLine: number,
    MACDLongLine: number,
    MACDSignalLine: number,
    ATRInterval: number,
    StochasticKInterval: number,
    StochasticDInterval: number,
    StochasticLongPoint: number,
    StochasticShortPoint: number,
    ichimokuTenkanPeriod: number,
    ichimokuKijunPeriod: number,
    ichimokuSenkouSpanBPeriod: number,
    takeTriggersWeights: boolean,
    takeDivergenceWeights: boolean,
    ichimokuChikouSpanPeriod: number,
    decisionWindow: number,
    minimalTriggersValue: number,
    minimalDivergenceValue: number,
    minimalApplicatorsValue: number,
    SMAWeight: number,
    MACDWeight: number,
    MACDDivWeight: number,
    StochasticWeight: number,
    StochasticDivWeight: number,
    IchimokuWeight: number,
    RSIDivWeight: number,
    SMADivWeight: number,
    oppositeCoefficient: number,
    placeOrders: boolean,
    orderWalletPercent: number,
    atrThreshold: number,
    leverage: number,
    stopLooseValue: number,
    stopLooseCharacter: string,
    trailingTriggerPriceValue: number,
    trailingTriggerPriceCharacter: string,
    trailingTriggerTailValue: number,
    trailingTriggerTailCharacter: string,
    closeOrders: boolean,
    ichimokuAtrThreshold: number,
    ichimokuThickCloudBonus: number,
    ichimokuExpandingCloudBonus: number,
    ichimokuContractingCloudPenalty: number,
    ichimokuAtrDistanceBonus: number,
    ichimokuAtrDistancePenalty: number,
    ichimokuStrongOppositionPenalty: number,
    ichimokuUncertaintyPenalty: number,
    ichimokuChikouConfirmationBonus: number,
    ichimokuChikouOppositionPenalty: number,
    ichimokuAtrHighThreshold: number,
    ichimokuAtrLowThreshold: number,
    duplicateTriggersCoefficient: number,
    duplicateTriggersDivCoefficient: number,
    macdBonusModerate: number,
    macdBonusStrong: number,
    macdHistogramExpansionBonus: number,
    macdHistogramContractionPenalty: number,
    macdStrongAverageMultiplier: number,
    rsiLowerThreshold: number,
    rsiUpperThreshold: number,
    rsiExtremeLow: number,
    rsiExtremeHigh: number,
    rsiModerateBonus: number,
    rsiExtremeBonus: number,
}

interface Quotation {
    time: UTCTimestamp;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: number;
}

interface Order {
    position: number;
    id: string;
    direction: string;
    openPrice: number;
    closePrice: number;
    coinAmount: number;
    status: string;
    leverage: number;
    tpPercent: number | null;
    slPercent: number | null;
    openDate: UTCTimestamp;
    closeDate: UTCTimestamp | null;
}

interface Trigger {
    time: UTCTimestamp;
    key: number;
    type: string;
    direction: string;
    info: Info;
}

interface Info {
    [key: string]: number | string;
}

const SimulationView: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const {getSimulation, getTriggers, getOrders} = api.useGetSimulation();
    const {getQuotations} = api.useGetQuotations();
    const [simulation, setSimulation] = useState<Simulation>();
    const [error, setError] = useState<string | null>(null);
    const [quotations, setQuotations] = useState<Quotation[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);

    const [triggerMarkers, setTriggerMarkers] = useState<Trigger[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<ReturnType<typeof createChart> | null>(null);
    const candlestickSeriesRef = useRef<ISeriesApi<'Candlestick'> | null>(null);
    const lineSeriesRef = useRef<ISeriesApi<'Line'> | null>(null);
    const [markers, setMarkers] = useState<SeriesMarker<Time>[]>([]);
    const navigate = useNavigate();
    const [simulationProgress, setSimulationProgress] = useState<{ percent: number, status: string }>({
        percent: 0,
        status: 'in_progress'
    });


    const [formData, setFormData] = useState({
        triggers: [] as string[],
    });

    const [show, setShow] = useState(false);
    const [metricsShow, setMetricsShoe] = useState(false);
    const [restartOpen, setRestartOpen] = useState(false);
    const openRestartModal = () => setRestartOpen(true);
    const closeRestartModal = () => setRestartOpen(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleLoad = () => {
        applyTriggers(simulation as Simulation, formData);
        setShow(false);
    };

    useEffect(() => {
        if (!simulation) return; // Проверяем, что simulation существует
        const socket = io(SOCKET_SERVER_URL as string);
        // Слушаем событие 'message' от сервера
        socket.on('message', (message) => {
            const data = message;

            if (data.simulationId && typeof data.percent === 'number' && typeof data.status === 'string') {
                // Обновляем прогресс и статус симуляции

                setSimulationProgress({percent: data.percent, status: data.status});

                if (data.status === 'completed') {
                    loadOrders(simulation);
                }
            }
        });

        return () => {
            socket.close();
        };
    }, [simulation]);

    const loadOrders = async (simulation: Simulation) => {
        const response = await getOrders(simulation.id);

        if (candlestickSeriesRef.current) {
            candlestickSeriesRef.current.setMarkers([]);
        }

        let orders: Order[] = response.data.orders.map((item: any) => ({
            position: item.position,
            id: item.id,
            direction: item.direction,
            openPrice: item.openPrice,
            closePrice: item.closePrice,
            coinAmount: item.coinAmount,
            status: item.status,
            leverage: item.leverage,
            tpPercent: item.tpPercent,
            slPercent: item.slPercent,
            openDate: item.openDate as UTCTimestamp,
            closeDate: item.closeDate as UTCTimestamp,
        }));

        const markers: SeriesMarker<UTCTimestamp>[] = [];

        orders.forEach(order => {
            // Маркер для открытия ордера
            const openMarker: SeriesMarker<UTCTimestamp> = {
                time: order.openDate,
                position: 'belowBar', // Можно использовать 'aboveBar', если нужно
                shape: 'arrowUp', // Условный маркер, можно изменить на другой
                color: getColorForOpenDirection(order.direction),
                id: order.id,
                text: `Open: ${order.position}`,
            };

            markers.push(openMarker);

            // Если ордер закрыт, добавляем маркер для закрытия
            if (order.closeDate) {
                const closeMarker: SeriesMarker<UTCTimestamp> = {
                    time: order.closeDate,
                    position: 'aboveBar', // Можно использовать 'belowBar', если нужно
                    shape: 'square', // Условный маркер, можно изменить на другой
                    color: getColorForCloseDirection(order.direction),
                    id: `${order.id}-close`,
                    text: `Close: ${order.position}`,
                };

                markers.push(closeMarker);
            }
        });

        addMarkersToSeries(markers);


        setOrders(orders);
    }

    const addMarkersToSeries = (newMarkers: SeriesMarker<UTCTimestamp>[]) => {
        if (candlestickSeriesRef.current) {
            const currentMarkers = candlestickSeriesRef.current.markers() || [];
            const updatedMarkers = [...currentMarkers, ...newMarkers];

            updatedMarkers.sort((a, b) => (a.time as number) - (b.time as number));

            candlestickSeriesRef.current.setMarkers(updatedMarkers);
        } else {
            setMarkers(prevMarkers => [...prevMarkers, ...newMarkers]);
        }
    };


    const getColorForOpenDirection = (direction: string): string => {
        return direction === 'LONG' ? 'green' : 'red'; // Задаем зеленый для покупки и красный для продажи
    };

    const getColorForCloseDirection = (direction: string): string => {
        return direction === 'LONG' ? 'white' : 'white'; // Задаем зеленый для покупки и красный для продажи
    };

    useEffect(() => {

        const loadSimulation = async () => {
            try {
                const response = await getSimulation(id as string);
                const simulationDate = response.data.simulation;

                simulationDate.startSimulationDate = moment(simulationDate.startSimulationDate * 1000);
                simulationDate.endSimulationDate = moment(simulationDate.endSimulationDate * 1000);

                setSimulation(simulationDate);

                loadQuotations(simulationDate);

            } catch (err: any) {
                setError('Failed to fetch simulation');
            } finally {
                setLoading(false);
            }
        }

        const loadQuotations = async (simulation: Simulation) => {
            // Рассчитываем количество минут между startDate и endDate
            const durationInMinutes = simulation.endSimulationDate.diff(simulation.startSimulationDate, 'minutes');

            // Рассчитываем количество запросов (каждый запрос возвращает до 1000 записей)
            const numberOfRequests = Math.ceil(durationInMinutes / 1000);

            // Массив для хранения всех свечей
            let quotations: Quotation[] = [];

            for (let i = 0; i < numberOfRequests; i++) {
                let from = simulation.startSimulationDate.clone().add(i * 1000, 'minutes').valueOf() / 1000;
                let to = simulation.startSimulationDate.clone().add((i + 1) * 1000, 'minutes').valueOf() / 1000;

                if (i + 1 === numberOfRequests) {
                    to = simulation.endSimulationDate.valueOf() / 1000;
                }
                if (i !== 0) {
                    from = from + 60;
                }

                const response = await getQuotations(from, to, '1');
                let quotationPack: Quotation[] = response.data.quotations.map((item: any) => ({
                    time: item.time as UTCTimestamp,
                    open: item.open,
                    high: item.high,
                    low: item.low,
                    close: item.close,
                }));

                quotations = quotations.concat(quotationPack);
            }

            setQuotations(quotations);
            renderChart(quotations);
            loadOrders(simulation);
        }


        const renderChart = async (quotations: Quotation[]) => {
            if (chartContainerRef.current && !chartRef.current) {
                const chart = createChart(chartContainerRef.current, {
                    width: chartContainerRef.current.clientWidth,
                    height: chartContainerRef.current.clientHeight,
                    timeScale: {
                        timeVisible: true,
                        secondsVisible: true,
                    },
                    layout: {
                        textColor: '#d1d4dc',
                        background: {color: '#131722'},
                    },
                    grid: {
                        vertLines: {
                            color: '#2B2B43',
                        },
                        horzLines: {
                            color: '#2B2B43',
                        },
                    },
                    crosshair: {
                        mode: CrosshairMode.Normal,
                    },
                });
                chartRef.current = chart;

                const candlestickSeries = chart.addCandlestickSeries({
                    upColor: '#4AFA9A',
                    downColor: '#FF4976',
                    borderDownColor: '#FF4976',
                    borderUpColor: '#4AFA9A',
                    wickDownColor: '#ff4976',
                    wickUpColor: '#4afa9a',
                });
                candlestickSeriesRef.current = candlestickSeries;

                // Установка данных prevCandles
                candlestickSeries.setData(quotations);
                chart.timeScale().fitContent();
            }


            setLoading(false);
        }

        loadSimulation();
    }, [id]);

    const handleSimulationList = () => {
        navigate(`/simulation`);
    };

    const applyTriggers = async (simulation: Simulation, formData: object) => {
        candlestickSeriesRef.current!.setMarkers([]);
        const triggersResponse = await getTriggers(simulation.id, formData);

        let markers: SeriesMarker<Time>[] = triggersResponse.data.triggers.map((item: any) => ({
            time: item.date as UTCTimestamp,
            position: item.direction === 'long' ? 'belowBar' : 'aboveBar' as SeriesMarkerPosition,
            color: item.direction === 'long' ? 'green' : 'red',
            shape: item.direction === 'long' ? 'arrowUp' : 'arrowDown' as SeriesMarkerShape,
            text: item.type + ' ' + item.key,
        }));

        setTriggerMarkers(triggersResponse.data.triggers);

        candlestickSeriesRef.current!.setMarkers(markers);
    }


    if (loading) {
        return <Layout><LoadingScreen/></Layout>;
    }

    if (error) {
        return <Layout>
            <div>{error}</div>
        </Layout>;
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;

        setFormData((prevFormData) => {
            const newTriggers = event.target.checked
                ? [...prevFormData.triggers, value] // добавляем значение, если чекбокс включен
                : prevFormData.triggers.filter((trigger) => trigger !== value); // удаляем значение, если чекбокс выключен

            return {
                ...prevFormData,
                triggers: newTriggers,
            };
        });
    };

    return <div className="d-flex flex-column min-vh-100">
        <Row className="flex-shrink-0 p-2">
            <Col className={"d-flex align-items-center"}>
                <Button variant="info" size={"sm"} className={"return-button"}  onClick={handleSimulationList}>Вернуться
                    к списку</Button>
                <SimulationProgress  progress={simulationProgress.percent || 0}/>
            </Col>
        </Row>
        <Row className="flex-grow-1">
            <Col>
                <div className={"chart-container"} ref={chartContainerRef}/>
            </Col>
        </Row>

        {/* Нижний блок по высоте содержимого */}
        <Row className="flex-shrink-0 p-2 footer-buttons">
            <Col>
                <Button variant="info" size={"sm"}  onClick={handleShow}>Загрузить
                    триггеры</Button>
                <Button variant="success" size={"sm"}
                        onClick={() => setMetricsShoe(true)}>Инфо по триггерам</Button>
                <Button variant="warning" size={"sm"}
                        onClick={() => openRestartModal()}>Пересимулировать</Button>
            </Col>
        </Row>
        <RestartModal simulation={simulation as Simulation} show={restartOpen}
                      onHide={closeRestartModal}></RestartModal>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Укажи триггеры</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Form.Check inline name="triggers" value="SMA:long" type="checkbox" label="SMA:long"
                                        checked={formData.triggers.includes('SMA:long')}
                                        onChange={handleCheckboxChange}/>

                            <Form.Check inline name="triggers" value="MACD:long" type="checkbox" label="MACD:long"
                                        checked={formData.triggers.includes('MACD:long')}
                                        onChange={handleCheckboxChange}/>

                            <Form.Check inline name="triggers" value="Stochastic:long" type="checkbox"
                                        label="Stochastic:long"
                                        checked={formData.triggers.includes('Stochastic:long')}
                                        onChange={handleCheckboxChange}/>
                            <Form.Check inline name="triggers" value="Ichimoku:long" type="checkbox"
                                        label="Ichimoku:long"
                                        checked={formData.triggers.includes('Ichimoku:long')}
                                        onChange={handleCheckboxChange}/>
                        </Col>
                        <Col sm={3}>
                            <Form.Check inline name="triggers" value="SMA:short" type="checkbox" label="SMA:short"
                                        checked={formData.triggers.includes('SMA:short')}
                                        onChange={handleCheckboxChange}/>
                            <Form.Check inline name="triggers" value="MACD:short" type="checkbox" label="MACD:short"
                                        checked={formData.triggers.includes('MACD:short')}
                                        onChange={handleCheckboxChange}/>
                            <Form.Check inline name="triggers" value="Stochastic:short" type="checkbox"
                                        label="Stochastic:short"
                                        checked={formData.triggers.includes('Stochastic:short')}
                                        onChange={handleCheckboxChange}/>
                            <Form.Check inline name="triggers" value="Ichimoku:short" type="checkbox"
                                        label="Ichimoku:short"
                                        checked={formData.triggers.includes('Ichimoku:short')}
                                        onChange={handleCheckboxChange}/>
                        </Col>
                        <Col sm={3}>
                            <Form.Check inline name="triggers" value="RSI:short:div" type="checkbox"
                                        label="RSI:short:div"
                                        checked={formData.triggers.includes('RSI:short:div')}
                                        onChange={handleCheckboxChange}/>
                        </Col>
                        <Col sm={3}>
                            <Form.Check inline name="triggers" value="RSI:long:div" type="checkbox" label="RSI:long:div"
                                        checked={formData.triggers.includes('RSI:long:div')}
                                        onChange={handleCheckboxChange}/>
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleLoad}>
                    Загрузить
                </Button>
            </Modal.Footer>
        </Modal>

        <MetricsInfo show={metricsShow} onHide={() => setMetricsShoe(false)}/>
    </div>
};

export default SimulationView;