import React, { createContext, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Auth/AuthContext';

interface RequestContextProps {
    request: (url: string, method: Method, data?: Data | null, params?: Params | null) => Promise<any>;
}

type Data = object;
type Params = object;
type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

const RequestContext = createContext<RequestContextProps | undefined>(undefined);

export const useRequest = (): RequestContextProps => {
    const context = useContext(RequestContext);
    if (!context) {
        throw new Error('useRequest must be used within a RequestProvider');
    }
    return context;
};

export const RequestProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { token } = useAuth();

    const request = async (url: string, method: Method, data: Data | null = null, params: Params | null = null): Promise<any> => {
        try {
            const headers: Record<string, string> = {
                'Content-Type': 'application/json',
            };

            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            const response = await axios({
                method: method,
                url: process.env.REACT_APP_API_BASE_URL + url,
                data: data,
                headers: headers,
                params: params
            });

            if (response.status >= 200 && response.status < 400) {
                return response.data;
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } catch (error: any) {
            if (error.response) {
                throw error.response;
            } else {
                throw new Error(`Network error: ${error.message}`);
            }
        }
    };

    return (
        <RequestContext.Provider value={{ request }}>
            {children}
        </RequestContext.Provider>
    );
};
