import React, { ReactNode } from 'react'
import './Layout.module.css'
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";

export default function System({ children }: { children: ReactNode }): React.JSX.Element {
    return (
        <div className="d-flex flex-column min-vh-100 ">
            <header>
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand href="/">CRYPTO</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/simulation">Симуляции</Nav.Link>
                                <Nav.Link href="/simulation/create">Создать симуляцию</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <main className="container-fluid flex-grow-1 d-flex">{children}</main>
            <footer className="bg-body-tertiary text-center text-lg-start">
                <Container>
                    <div className="text-center p-3">
                        © 2024 Copyright:
                    </div>
                </Container>
            </footer>
        </div>
    )
}