import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { parse, serialize } from 'cookie';

interface AuthContextProps {
    token: string | null;
    setToken: (token: string) => void;
    removeToken: () => void;
    isTokenLoaded: boolean;
    getToken: () => string|null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [token, setTokenState] = useState<string | null>(null);
    const [isTokenLoaded, setIsTokenLoaded] = useState(false);

    useEffect(() => {
        const cookies = parse(document.cookie);
        const storedToken = cookies.token;
        if (storedToken) {
            setTokenState(storedToken);
        }
        setIsTokenLoaded(true);
    }, []);

    const getToken = () => {
        return token;
    };

    const setToken = (newToken: string) => {
        document.cookie = serialize('token', newToken, { path: '/', maxAge: 60 * 60 * 24 * 7 }); // Expires in 7 days
        setTokenState(newToken);
    };

    const removeToken = () => {
        document.cookie = serialize('token', '', { path: '/', maxAge: -1 });
        setTokenState(null);
    };

    return (
        <AuthContext.Provider value={{ token, setToken, removeToken, isTokenLoaded, getToken }}>
            {children}
        </AuthContext.Provider>
    );
};
