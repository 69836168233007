import React from "react";
import {Accordion, Modal} from "react-bootstrap";

interface MetricsInfoProps {
    show: boolean;
    onHide: () => void;
}

const MetricsInfo: React.FC<MetricsInfoProps> = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} size={"xl"}>
            <Modal.Header closeButton>
                <Modal.Title>Описание метрик</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>RSI</Accordion.Header>
                        <Accordion.Body>
                            Определяет перекупленность или перепроданность. Если  70, считается перекупленным, скоро возможен разворот вниз. Если меньше 30, то может пойти вверх. Если пересекает 30 снизу вверх, сигнал о покупке, если 70 сверху вниз - к продаже.
                            Бычья дивергенция: Цена делает более низкие минимумы, а RSI показывает более высокие минимумы — это сигнализирует о возможном развороте вверх.
                            Медвежья дивергенция: Цена делает более высокие максимумы, а RSI показывает более низкие максимумы — это сигнализирует о возможном развороте вниз.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
        </Modal>
    );
};

export default MetricsInfo;
