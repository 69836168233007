import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Layout from './../../Layout';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import api from '../../Api/api';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import {Moment} from "moment/moment";
import 'moment/locale/ru';
import './CreateSimulation.css'
import './datepicker.css';

moment.locale('ru');

const Login: React.FC = () => {
    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        symbol: 'BTCUSDT',
        RSIInterval: 14,
        RSIDevergenceWindow: 3,
        intervalSMAFrom: 5,
        intervalSMATo: 15,
        SMAInterval: 14,
        MACDShortLine: 12,
        MACDLongLine: 26,
        MACDSignalLine: 9,
        ATRInterval: 14,
        StochasticKInterval: 14,
        StochasticDInterval: 3,
        StochasticLongPoint: 20,
        StochasticShortPoint: 80,
        ichimokuTenkanPeriod: 9,
        ichimokuKijunPeriod: 26,
        ichimokuSenkouSpanBPeriod: 52,
        ichimokuChikouSpanPeriod: 26,
    });
    const [errors, setErrors] = useState<{
        startDate?: string;
        endDate?: string;
        symbol?: string;
        RSIInterval?: string;
        RSIDevergenceWindow?: string;
        intervalSMAFrom?: string,
        intervalSMATo?: string,
        SMAInterval?: string,
        MACDShortLine?: string,
        MACDLongLine?: string,
        MACDSignalLine?: string,
        ATRInterval?: string,
        StochasticKInterval?: string,
        StochasticDInterval?: string,
        StochasticLongPoint?: string,
        StochasticShortPoint?: string,
        ichimokuTenkanPeriod?: string,
        ichimokuKijunPeriod?: string,
        ichimokuSenkouSpanBPeriod?: string,
        ichimokuChikouSpanPeriod?: string,
    }>({});
    const navigate = useNavigate();
    const {createSimulation} = api.useCreateSimulation();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({...prevData, [name]: value}));
        if (errors[name as keyof typeof errors]) {
            setErrors((prevErrors) => ({...prevErrors, [name]: undefined}));
        }
    };

    const handleStartDate = (value: Moment) => {
        setFormData({
            ...formData,
            ['startDate']: value.toISOString()
        });
        if (errors.startDate) {
            setErrors((prevErrors) => ({...prevErrors, startDate: undefined}));
        }
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        setFormData((prevData) => ({...prevData, [name]: checked}));
    };

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const handleEndDate = (value: Moment) => {
        setFormData({
            ...formData,
            ['endDate']: value.toISOString()
        });
        if (errors.endDate) {
            setErrors((prevErrors) => ({...prevErrors, endDate: undefined}));
        }
    };

    const handleCreate = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await createSimulation(formData);
            navigate('/simulation');
        } catch (error: any) {
            if (error && error.status === 400 && error.data && error.data.errors) {
                setErrors(error.data.errors);
            } else {
                console.error(error.data.message);
            }
        }
    };

    return (
        <Layout>
            <Form onSubmit={handleCreate}>
                <Row>
                    <Col lg={2}>
                        <h5>Даты симуляций</h5>
                        <Form.Label>начало</Form.Label>
                        <Datetime
                            dateFormat="DD MMMM YYYY"
                            timeFormat="HH:mm:ss"
                            utc={true}
                            locale="ru"
                            value={formData.startDate ? moment(formData.startDate) : ''}
                            onChange={handleStartDate}
                            inputProps={{className: errors.startDate ? 'form-control is-invalid' : 'form-control'}}
                        />
                        <Form.Label>конец</Form.Label>
                        <Datetime
                            utc={true}
                            dateFormat="DD MMMM YYYY"
                            timeFormat="HH:mm:ss"
                            locale="ru"
                            value={formData.endDate ? moment(formData.endDate) : ''}
                            onChange={handleEndDate}
                            inputProps={{className: errors.endDate ? 'form-control is-invalid' : 'form-control'}}
                        />
                        <Form.Label>Монета</Form.Label>
                        <Form.Select value={formData.symbol} name="symbol" onChange={handleSelect}>
                            <option value="BTCUSDT">BTCUSDT</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2}>
                        <h5>RSI</h5>
                        <Form.Label>Основной интервал</Form.Label>
                        <Form.Control
                            type="number"
                            name={'RSIInterval'}
                            value={formData.RSIInterval}
                            onChange={handleChange}
                            isInvalid={!!errors.RSIInterval}
                        />
                        <Form.Label>Окно дивергенции</Form.Label>
                        <Form.Control
                            type="number"
                            name={'RSIDevergenceWindow'}
                            value={formData.RSIDevergenceWindow}
                            onChange={handleChange}
                            isInvalid={!!errors.RSIDevergenceWindow}
                        />
                    </Col>
                    <Col lg={2}>
                        <h5>SMA</h5>
                        <Form.Label>Когда</Form.Label>
                        <Form.Control
                            type="number"
                            name={'intervalSMAFrom'}
                            value={formData.intervalSMAFrom}
                            onChange={handleChange}
                            isInvalid={!!errors.intervalSMAFrom}
                        />
                        <Form.Label>пересекает</Form.Label>
                        <Form.Control
                            type="number"
                            name={'intervalSMATo'}
                            value={formData.intervalSMATo}
                            onChange={handleChange}
                            isInvalid={!!errors.intervalSMATo}
                        />
                        <Form.Label>Рассчётная</Form.Label>
                        <Form.Control
                            type="number"
                            name={'SMAInterval'}
                            value={formData.SMAInterval}
                            onChange={handleChange}
                            isInvalid={!!errors.SMAInterval}
                        />
                    </Col>
                    <Col lg={2}>
                        <h5>MACD</h5>
                        <Form.Label>Short Line</Form.Label>
                        <Form.Control
                            type="number"
                            name={'MACDShortLine'}
                            value={formData.MACDShortLine}
                            onChange={handleChange}
                            isInvalid={!!errors.MACDShortLine}
                        />
                        <Form.Label>Long line</Form.Label>
                        <Form.Control
                            type="number"
                            name={'MACDLongLine'}
                            value={formData.MACDLongLine}
                            onChange={handleChange}
                            isInvalid={!!errors.MACDLongLine}
                        />
                        <Form.Label>Signal line</Form.Label>
                        <Form.Control
                            type="number"
                            name={'MACDSignalLine'}
                            value={formData.MACDSignalLine}
                            onChange={handleChange}
                            isInvalid={!!errors.MACDSignalLine}
                        />
                    </Col>
                    <Col lg={2}>
                        <h5>ATR</h5>
                        <Form.Label>Интервал</Form.Label>
                        <Form.Control
                            type="number"
                            name={'ATRInterval'}
                            value={formData.ATRInterval}
                            onChange={handleChange}
                            isInvalid={!!errors.ATRInterval}
                        />
                    </Col>
                    <Col lg={2}>
                        <h5>Stochastic</h5>
                        <Form.Label>K - линия</Form.Label>
                        <Form.Control
                            type="number"
                            name={'StochasticKInterval'}
                            value={formData.StochasticKInterval}
                            onChange={handleChange}
                            isInvalid={!!errors.StochasticKInterval}
                        />
                        <Form.Label>D - линия</Form.Label>
                        <Form.Control
                            type="number"
                            name={'StochasticDInterval'}
                            value={formData.StochasticDInterval}
                            onChange={handleChange}
                            isInvalid={!!errors.StochasticDInterval}
                        />
                        <Form.Label>Long Point</Form.Label>
                        <Form.Control
                            type="number"
                            name={'StochasticLongPoint'}
                            value={formData.StochasticLongPoint}
                            onChange={handleChange}
                            isInvalid={!!errors.StochasticLongPoint}
                        />
                        <Form.Label>Short Point</Form.Label>
                        <Form.Control
                            type="number"
                            name={'StochasticShortPoint'}
                            value={formData.StochasticShortPoint}
                            onChange={handleChange}
                            isInvalid={!!errors.StochasticShortPoint}
                        />
                    </Col>
                    <Col lg={2}>
                        <h5>Ichimoku Cloud</h5>
                        <Form.Label>Tenkan</Form.Label>
                        <Form.Control
                            type="number"
                            name={'ichimokuTenkanPeriod'}
                            value={formData.ichimokuTenkanPeriod}
                            onChange={handleChange}
                            isInvalid={!!errors.ichimokuTenkanPeriod}
                        />
                        <Form.Label>Kijun</Form.Label>
                        <Form.Control
                            type="number"
                            name={'ichimokuKijunPeriod'}
                            value={formData.ichimokuKijunPeriod}
                            onChange={handleChange}
                            isInvalid={!!errors.ichimokuKijunPeriod}
                        />
                        <Form.Label>Senkou Span B</Form.Label>
                        <Form.Control
                            type="number"
                            name={'ichimokuSenkouSpanBPeriod'}
                            value={formData.ichimokuSenkouSpanBPeriod}
                            onChange={handleChange}
                            isInvalid={!!errors.ichimokuSenkouSpanBPeriod}
                        />
                        <Form.Label>Chikou span</Form.Label>
                        <Form.Control
                            type="number"
                            name={'ichimokuChikouSpanPeriod'}
                            value={formData.ichimokuChikouSpanPeriod}
                            onChange={handleChange}
                            isInvalid={!!errors.ichimokuChikouSpanPeriod}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="success" type="submit">
                            Создать
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default Login;
