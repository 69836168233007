import React from 'react';
import './index.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
);

reportWebVitals();
