import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './../Layout';
import { Button, Form } from 'react-bootstrap';
import api from '../Api/api';
import { useAuth } from '../Auth/AuthContext';

const Login: React.FC = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState<{ email?: string; password?: string }>({});
    const navigate = useNavigate();
    const { setToken } = useAuth();
    const {login} = api.useLogin();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (errors[name as keyof typeof errors]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
        }
    };

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await login(formData.email, formData.password);
            setToken(response.data.token);
            navigate('/');
        } catch (error: any) {
            console.error('Login error', error);
            if (error && error.status === 400 && error.data && error.data.errors) {
                setErrors(error.data.errors);
            } else {
                // General error handling (e.g., network error)
                setErrors({ email: 'An error occurred', password: 'An error occurred' });
            }
        }
    };

    return (
        <Layout>
            <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.email}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.password}
                    </Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </Layout>
    );
};

export default Login;
