import React, {useState} from 'react';
import {Simulation} from './SimulationView'
import {Button, Col, Form, FormGroup, Modal, Nav, Row, Tab, Tabs} from "react-bootstrap";
import api from "../Api";
import {Checkbox} from "material-ui-core";

interface SimulationPopupProps {
    simulation: Simulation;
    show: boolean;
    onHide: () => void;
}

const RestartModal: React.FC<SimulationPopupProps> = ({simulation, show, onHide}) => {
    const {restartSimulation} = api.useRestartSimulation();
    const [formData, setFormData] = useState({
        decisionWindow: simulation.decisionWindow,
        minimalTriggersValue: simulation.minimalTriggersValue,
        minimalDivergenceValue: simulation.minimalDivergenceValue,
        minimalApplicatorsValue: simulation.minimalApplicatorsValue,
        SMAWeight: simulation.SMAWeight,
        MACDWeight: simulation.MACDWeight,
        MACDDivWeight: simulation.MACDDivWeight,
        StochasticWeight: simulation.StochasticWeight,
        StochasticDivWeight: simulation.StochasticDivWeight,
        IchimokuWeight: simulation.StochasticWeight,
        SMADivWeight: simulation.StochasticDivWeight,
        RSIDivWeight: simulation.MACDDivWeight,
        oppositeCoefficient: simulation.oppositeCoefficient,
        placeOrders: simulation.placeOrders,
        orderWalletPercent: simulation.orderWalletPercent,
        atrThreshold: simulation.atrThreshold,
        leverage: simulation.leverage,
        stopLooseValue: simulation.stopLooseValue,
        stopLooseCharacter: simulation.stopLooseCharacter,
        trailingTriggerPriceValue: simulation.trailingTriggerPriceValue,
        trailingTriggerPriceCharacter: simulation.trailingTriggerPriceCharacter,
        trailingTriggerTailValue: simulation.trailingTriggerTailValue,
        trailingTriggerTailCharacter: simulation.trailingTriggerTailCharacter,
        closeOrders: simulation.closeOrders,
        ichimokuAtrThreshold: simulation.ichimokuAtrThreshold,
        ichimokuThickCloudBonus: simulation.ichimokuThickCloudBonus,
        ichimokuExpandingCloudBonus: simulation.ichimokuExpandingCloudBonus,
        ichimokuContractingCloudPenalty: simulation.ichimokuContractingCloudPenalty,
        ichimokuAtrDistanceBonus: simulation.ichimokuAtrDistanceBonus,
        ichimokuAtrDistancePenalty: simulation.ichimokuAtrDistancePenalty,
        ichimokuStrongOppositionPenalty: simulation.ichimokuStrongOppositionPenalty,
        ichimokuUncertaintyPenalty: simulation.ichimokuUncertaintyPenalty,
        ichimokuChikouConfirmationBonus: simulation.ichimokuChikouConfirmationBonus,
        ichimokuChikouOppositionPenalty: simulation.ichimokuChikouOppositionPenalty,
        ichimokuAtrHighThreshold: simulation.ichimokuAtrHighThreshold,
        ichimokuAtrLowThreshold: simulation.ichimokuAtrLowThreshold,
        duplicateTriggersDivCoefficient: simulation.duplicateTriggersDivCoefficient,
        duplicateTriggersCoefficient: simulation.duplicateTriggersCoefficient,
        takeTriggersWeights: simulation.takeTriggersWeights,
        takeDivergenceWeights: simulation.takeDivergenceWeights,
        macdBonusModerate: simulation.macdBonusModerate,
        macdBonusStrong: simulation.macdBonusStrong,
        macdHistogramContractionPenalty: simulation.macdHistogramContractionPenalty,
        macdStrongAverageMultiplier: simulation.macdStrongAverageMultiplier,
        macdHistogramExpansionBonus: simulation.macdHistogramExpansionBonus,
        rsiLowerThreshold: simulation.rsiLowerThreshold,
        rsiUpperThreshold: simulation.rsiUpperThreshold,
        rsiExtremeLow: simulation.rsiExtremeLow,
        rsiExtremeHigh: simulation.rsiExtremeHigh,
        rsiModerateBonus: simulation.rsiModerateBonus,
        rsiExtremeBonus: simulation.rsiExtremeBonus,
    });
    const [errors, setErrors] = useState<{
        decisionWindow?: string;
        minimalTriggersValue?: string;
        minimalApplicatorsValue?: string;
        minimalDivergenceValue?: string;
        SMAWeight?: string;
        MACDWeight?: string;
        MACDDivWeight?: string;
        StochasticWeight?: string;
        StochasticDivWeight?: string;
        IchimokuWeight?: string;
        SMADivWeight?: string;
        RSIDivWeight?: string;
        oppositeCoefficient?: string;
        placeOrders?: string;
        orderWalletPercent?: string;
        atrThreshold?: string;
        leverage?: string;
        stopLooseValue?: string,
        stopLooseCharacter?: string,
        trailingTriggerPriceValue?: string,
        trailingTriggerPriceCharacter?: string,
        trailingTriggerTailValue?: string,
        trailingTriggerTailCharacter?: string,
        closeOrders?: string,
        ichimokuAtrThreshold?: string,
        ichimokuThickCloudBonus?: string,
        ichimokuExpandingCloudBonus?: string,
        ichimokuContractingCloudPenalty?: string,
        ichimokuAtrDistanceBonus?: string,
        ichimokuAtrDistancePenalty?: string,
        ichimokuStrongOppositionPenalty?: string,
        ichimokuUncertaintyPenalty?: string,
        ichimokuChikouConfirmationBonus?: string,
        ichimokuChikouOppositionPenalty?: string,
        ichimokuAtrHighThreshold?: string,
        ichimokuAtrLowThreshold?: string,
        duplicateTriggersDivCoefficient?: string,
        duplicateTriggersCoefficient?: string,
        takeTriggersWeights?: string,
        takeDivergenceWeights?: string,
        macdBonusModerate?: string,
        macdBonusStrong?: string,
        macdHistogramContractionPenalty?: string,
        macdStrongAverageMultiplier?: string,
        macdHistogramExpansionBonus?: string,
        rsiLowerThreshold?: string,
        rsiUpperThreshold?: string,
        rsiExtremeLow?: string,
        rsiExtremeHigh?: string,
        rsiModerateBonus?: string,
        rsiExtremeBonus?: string,

    }>({});

    const handleCreate = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await restartSimulation(simulation.id, formData);
            onHide();
        } catch (error: any) {
            if (error && error.status === 400 && error.data && error.data.errors) {
                setErrors(error.data.errors);
            } else {
                console.error(error.data.message);
            }
        }
    };

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({...prevData, [name]: value}));
        if (errors[name as keyof typeof errors]) {
            setErrors((prevErrors) => ({...prevErrors, [name]: undefined}));
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name} = event.target;
        
        // Обновляем состояние formData
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: event.target.checked
        }));
    };

    return (
        <Modal show={show} size={"xl"} onHide={onHide}>
            <Modal.Header closeButton><Modal.Title>Перезапустить</Modal.Title></Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleCreate}>
                    <Tab.Container defaultActiveKey="decision">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="decision">Параметры принятия решений</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="triggers">Триггеры</Nav.Link>
                                        <Nav.Link eventKey="divergention">Дивергенции</Nav.Link>
                                        <Nav.Link eventKey="placeOrders">Открытие ордеров</Nav.Link>
                                        <Nav.Link eventKey="closeOrders">Закрытие ордеров</Nav.Link>
                                        <Nav.Link eventKey="applicators">Аппликаторы</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="decision">
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Окно</Form.Label>
                                            <Form.Control
                                                size={"sm"}
                                                type="number"
                                                name={'decisionWindow'}
                                                value={formData.decisionWindow}
                                                onChange={handleChange}
                                                isInvalid={!!errors.decisionWindow}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Порог веса для триггеров</Form.Label>
                                            <Form.Control
                                                size={"sm"}
                                                type="number"
                                                name={'minimalTriggersValue'}
                                                value={formData.minimalTriggersValue}
                                                onChange={handleChange}
                                                isInvalid={!!errors.minimalTriggersValue}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Check inline name="takeTriggersWeights"
                                                        value="takeTriggersWeights" type="checkbox"
                                                        label="Использовать вес триггеров для дивергенций"
                                                        checked={formData.takeTriggersWeights}
                                                        onChange={handleCheckboxChange}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Порог веса для дивергенций</Form.Label>
                                            <Form.Control
                                                size={"sm"}
                                                type="number"
                                                name={'minimalDivergenceValue'}
                                                value={formData.minimalDivergenceValue}
                                                onChange={handleChange}
                                                isInvalid={!!errors.minimalDivergenceValue}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Check inline name="takeDivergenceWeights"
                                                        value="takeDivergenceWeights" type="checkbox"
                                                        label="Использовать вес дивергенций для аппликаторов"
                                                        checked={formData.takeDivergenceWeights}
                                                        onChange={handleCheckboxChange}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Порог веса для аппликаторов</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'minimalApplicatorsValue'}
                                                value={formData.minimalApplicatorsValue}
                                                onChange={handleChange}
                                                isInvalid={!!errors.minimalApplicatorsValue}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Коэффициент веса противоположных триггеров</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'oppositeCoefficient'}
                                                value={formData.oppositeCoefficient}
                                                onChange={handleChange}
                                                isInvalid={!!errors.oppositeCoefficient}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Коэффициент дублирующихся триггеров</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'duplicateTriggersCoefficient'}
                                                value={formData.duplicateTriggersCoefficient}
                                                onChange={handleChange}
                                                isInvalid={!!errors.duplicateTriggersCoefficient}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Коэффициент дублирующихся дивергенций</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'duplicateTriggersDivCoefficient'}
                                                value={formData.duplicateTriggersDivCoefficient}
                                                onChange={handleChange}
                                                isInvalid={!!errors.duplicateTriggersDivCoefficient}
                                            />
                                        </FormGroup>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="triggers">
                                        <FormGroup>
                                            <Form.Label column={"sm"}>SMA</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'SMAWeight'}
                                                value={formData.SMAWeight}
                                                onChange={handleChange}
                                                isInvalid={!!errors.SMAWeight}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Ichimoku</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'IchimokuWeight'}
                                                value={formData.IchimokuWeight}
                                                onChange={handleChange}
                                                isInvalid={!!errors.IchimokuWeight}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>MACD</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'MACDWeight'}
                                                value={formData.MACDWeight}
                                                onChange={handleChange}
                                                isInvalid={!!errors.MACDWeight}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Stochastic</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'StochasticWeight'}
                                                value={formData.StochasticWeight}
                                                onChange={handleChange}
                                                isInvalid={!!errors.StochasticWeight}
                                            />
                                        </FormGroup>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="divergention">
                                        <FormGroup>
                                            <Form.Label column={"sm"}>RSI</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'RSIDivWeight'}
                                                value={formData.RSIDivWeight}
                                                onChange={handleChange}
                                                isInvalid={!!errors.RSIDivWeight}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>MACD</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'MACDDivWeight'}
                                                value={formData.MACDDivWeight}
                                                onChange={handleChange}
                                                isInvalid={!!errors.MACDDivWeight}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>SMA</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'SMADivWeight'}
                                                value={formData.SMADivWeight}
                                                onChange={handleChange}
                                                isInvalid={!!errors.SMADivWeight}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Stochastic</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'StochasticDivWeight'}
                                                value={formData.StochasticDivWeight}
                                                onChange={handleChange}
                                                isInvalid={!!errors.StochasticDivWeight}
                                            />
                                        </FormGroup>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="placeOrders">
                                        <FormGroup>
                                            <Form.Check inline name="placeOrders" value="placeOrders" type="checkbox"
                                                        label="Создавать ордера"
                                                        checked={formData.placeOrders}
                                                        onChange={handleCheckboxChange}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Процент ордера от баланса</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'orderWalletPercent'}
                                                value={formData.orderWalletPercent}
                                                onChange={handleChange}
                                                isInvalid={!!errors.orderWalletPercent}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Минимальный процент волатильности</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'atrThreshold'}
                                                value={formData.atrThreshold}
                                                onChange={handleChange}
                                                isInvalid={!!errors.atrThreshold}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Кредитное плечо</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'leverage'}
                                                value={formData.leverage}
                                                onChange={handleChange}
                                                isInvalid={!!errors.leverage}
                                            />
                                        </FormGroup>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="closeOrders">
                                        <Form.Check inline name="closeOrders" value="closeOrders" type="checkbox"
                                                    label="Закрывать ордера"
                                                    checked={formData.closeOrders}
                                                    onChange={handleCheckboxChange}/>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Stop Loose параметры</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'stopLooseValue'}
                                                value={formData.stopLooseValue}
                                                onChange={handleChange}
                                                isInvalid={!!errors.stopLooseValue}
                                            />
                                            <Form.Select value={formData.stopLooseCharacter} name="stopLooseCharacter" onChange={handleSelect}>
                                                <option value="pp">Процент от цены</option>
                                                <option value="op">Процент от ордера</option>
                                                <option value="atr">ATR</option>
                                                <option value="usdt">USDT</option>
                                            </Form.Select>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Trailing Stop параметры</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'trailingTriggerPriceValue'}
                                                value={formData.trailingTriggerPriceValue}
                                                onChange={handleChange}
                                                isInvalid={!!errors.trailingTriggerPriceValue}
                                            />
                                            <Form.Select value={formData.trailingTriggerPriceCharacter} name="trailingTriggerPriceCharacter" onChange={handleSelect}>
                                                <option value="pp">Процент от цены</option>
                                                <option value="op">Процент от ордера</option>
                                                <option value="atr">ATR</option>
                                                <option value="usdt">USDT</option>
                                            </Form.Select>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label column={"sm"}>Trailing Stop хвост</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'trailingTriggerTailValue'}
                                                value={formData.trailingTriggerTailValue}
                                                onChange={handleChange}
                                                isInvalid={!!errors.trailingTriggerTailValue}
                                            />
                                            <Form.Select value={formData.trailingTriggerTailCharacter} name="trailingTriggerTailCharacter" onChange={handleSelect}>
                                                <option value="pp">Процент от цены</option>
                                                <option value="op">Процент от ордера</option>
                                                <option value="atr">ATR</option>
                                                <option value="usdt">USDT</option>
                                            </Form.Select>
                                        </FormGroup>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="applicators">
                                        <Tabs defaultActiveKey="ichimoku" className="mb-3">
                                            <Tab eventKey="ichimoku" title="Ишимоку">
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuAtrThreshold</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuAtrThreshold'}
                                                        value={formData.ichimokuAtrThreshold}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuAtrThreshold}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuThickCloudBonus</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuThickCloudBonus'}
                                                        value={formData.ichimokuThickCloudBonus}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuThickCloudBonus}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuExpandingCloudBonus</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuExpandingCloudBonus'}
                                                        value={formData.ichimokuExpandingCloudBonus}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuExpandingCloudBonus}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuContractingCloudPenalty</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuContractingCloudPenalty'}
                                                        value={formData.ichimokuContractingCloudPenalty}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuContractingCloudPenalty}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuAtrDistanceBonus</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuAtrDistanceBonus'}
                                                        value={formData.ichimokuAtrDistanceBonus}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuAtrDistanceBonus}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuAtrDistancePenalty</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuAtrDistancePenalty'}
                                                        value={formData.ichimokuAtrDistancePenalty}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuAtrDistancePenalty}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuStrongOppositionPenalty</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuStrongOppositionPenalty'}
                                                        value={formData.ichimokuStrongOppositionPenalty}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuStrongOppositionPenalty}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuUncertaintyPenalty</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuUncertaintyPenalty'}
                                                        value={formData.ichimokuUncertaintyPenalty}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuUncertaintyPenalty}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuChikouConfirmationBonus</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuChikouConfirmationBonus'}
                                                        value={formData.ichimokuChikouConfirmationBonus}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuChikouConfirmationBonus}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuChikouOppositionPenalty</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuChikouOppositionPenalty'}
                                                        value={formData.ichimokuChikouOppositionPenalty}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuChikouOppositionPenalty}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuAtrHighThreshold</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuAtrHighThreshold'}
                                                        value={formData.ichimokuAtrHighThreshold}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuAtrHighThreshold}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>ichimokuAtrLowThreshold</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'ichimokuAtrLowThreshold'}
                                                        value={formData.ichimokuAtrLowThreshold}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.ichimokuAtrLowThreshold}
                                                    />
                                                </FormGroup>
                                            </Tab>
                                            <Tab eventKey="macd" title="MACD">
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>macdBonusModerate</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'macdBonusModerate'}
                                                        value={formData.macdBonusModerate}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.macdBonusModerate}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>macdBonusStrong</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'macdBonusStrong'}
                                                        value={formData.macdBonusStrong}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.macdBonusStrong}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>macdHistogramExpansionBonus</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'macdHistogramExpansionBonus'}
                                                        value={formData.macdHistogramExpansionBonus}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.macdHistogramExpansionBonus}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>macdHistogramContractionPenalty</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'macdHistogramContractionPenalty'}
                                                        value={formData.macdHistogramContractionPenalty}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.macdHistogramContractionPenalty}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>macdStrongAverageMultiplier</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'macdStrongAverageMultiplier'}
                                                        value={formData.macdStrongAverageMultiplier}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.macdStrongAverageMultiplier}
                                                    />
                                                </FormGroup>
                                            </Tab>
                                            <Tab eventKey="rss" title="RSI">
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>rsiLowerThreshold</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'rsiLowerThreshold'}
                                                        value={formData.rsiLowerThreshold}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.rsiLowerThreshold}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>rsiUpperThreshold</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'rsiUpperThreshold'}
                                                        value={formData.rsiUpperThreshold}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.rsiUpperThreshold}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>rsiExtremeLow</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'rsiExtremeLow'}
                                                        value={formData.rsiExtremeLow}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.rsiExtremeLow}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>rsiExtremeHigh</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'rsiExtremeHigh'}
                                                        value={formData.rsiExtremeHigh}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.rsiExtremeHigh}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>rsiModerateBonus</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'rsiModerateBonus'}
                                                        value={formData.rsiModerateBonus}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.rsiModerateBonus}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label column={"sm"}>rsiExtremeBonus</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={'rsiExtremeBonus'}
                                                        value={formData.rsiExtremeBonus}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.rsiExtremeBonus}
                                                    />
                                                </FormGroup>
                                            </Tab>
                                        </Tabs>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"success"} onClick={handleCreate}>Запустить</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RestartModal;