import React, { useEffect, useState } from 'react';
import './SimulationList.css';
import Layout from './../Layout';
import api from '../Api/api';
import {Button, Row, Table} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {Moment} from "moment/moment";
import * as moment from "moment";
import {io} from "socket.io-client";
import SimulationProgress from "./SimulationProgress";
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;

interface Simulation {
    id: string;
    status: string;
    startSimulationDate: Moment;
    endSimulationDate: Moment;
    startBalance: number;
    endBalance: number|null
}

const SimulationList: React.FC = () => {
    const [simulations, setSimulations] = useState<Simulation[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const {getSimulations} = api.useGetSimulations();
    const {deleteSimulation} = api.useGetSimulation();
    const navigate = useNavigate();
    const [simulationProgress, setSimulationProgress] = useState<{ [key: string]: number }>({});

    useEffect(() => {
        const socket = io(SOCKET_SERVER_URL as string);
        // Слушаем событие 'message' от сервера
        socket.on('message', (message) => {
            const data = message;

            if (data.simulationId && typeof data.percent === 'number' && typeof data.status === 'string') {
                // Обновляем прогресс и статус симуляции
                setSimulations(prevSimulations =>
                    prevSimulations.map(simulation =>
                        simulation.id === data.simulationId
                            ? { ...simulation, status: data.status }
                            : simulation
                    )
                );

                setSimulationProgress(prevProgress => ({
                    ...prevProgress,
                    [data.simulationId]: data.percent
                }));
            }
        });

        return () => {
            socket.close();
        };
    }, []);


    useEffect(() => {
        fetchSimulations();
    }, []);

    const fetchSimulations = async () => {
        try {
            const response = await getSimulations();

            const simulations = response.data.simulations.map((item: any) => ({
                id: item.id,
                status: item.status,
                startSimulationDate: moment(item.startSimulationDate * 1000).utc(),
                endSimulationDate: moment(item.endSimulationDate * 1000).utc(),
                startBalance: item.startBalance,
                endBalance: item.endBalance,
            }));

            setSimulations(simulations);
        } catch (err: any) {
            setError('Failed to fetch simulations');
        } finally {
            setLoading(false);
        }
    };

    const handleViewSimulation = (id: string) => {
        navigate(`/simulation/${id}`);
    };

    const handleDeleteSimulation = async (id: string) => {
        try {
            await deleteSimulation(id);
            await fetchSimulations();
        } catch (err) {
            console.error('Failed to delete simulation', err);
        }
    };

    if (loading) {
        return <Layout><LoadingScreen/></Layout>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Layout>
            <Row>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Даты</th>
                        <th>Баланс</th>
                        <th>Статус</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {simulations.map((simulation) => (
                        <tr key={simulation.id}>
                            <td>
                                {simulation.startSimulationDate.format("DD MMMM YYYY HH:mm:ss")} - {simulation.endSimulationDate.format("DD MMMM YYYY HH:mm:ss")}
                            </td>
                            <td>
                                {simulation.startBalance} $&nbsp;
                                {simulation.endBalance && <>
                                    -{'>'}&nbsp;
                                {simulation.endBalance} $
                                </>}
                            </td>
                            <td>
                                {simulation.status === 'in_progress' ? (
                                    <SimulationProgress progress={simulationProgress[simulation.id] || 0} />
                                ) : (
                                    <>{simulation.status}</>
                                )}
                            </td>
                            <td>
                                {simulation.status === 'completed' && (
                                    <>
                                        <Button
                                            variant="primary"
                                            className="me-2"
                                            onClick={() => handleViewSimulation(simulation.id)}
                                        >
                                            View
                                        </Button>
                                        <Button
                                            variant="danger"
                                            onClick={() => handleDeleteSimulation(simulation.id)}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )}
                                {simulation.status === 'failed' && (
                                    <>
                                        <Button
                                            variant="danger"
                                            onClick={() => handleDeleteSimulation(simulation.id)}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Row>
        </Layout>
    );
};

export default SimulationList;
