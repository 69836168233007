import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from "../Login/Login";
import ProtectedRoute from "../Auth/ProtectedRoute";
import Home from "../Home";
import {AuthProvider} from "../Auth/AuthContext";
import CreateSimulation from "../Simulation/Create/CreateSimulation";
import {RequestProvider} from "../Api/RequestContext";
import SimulationList from "../SimulationList/SimulationList";
import SimulationView from "../SimulationView/SimulationView";
import './App.css';

const App: React.FC = () => {
    return (
        <AuthProvider>
        <RequestProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/" element={<ProtectedRoute><Home/></ProtectedRoute>}/>
                    <Route path="/simulation/create" element={<ProtectedRoute><CreateSimulation/></ProtectedRoute>}/>
                    <Route path="/simulation" element={<ProtectedRoute><SimulationList/></ProtectedRoute>}/>
                    <Route path="/simulation/:id" element={<ProtectedRoute><SimulationView/></ProtectedRoute>}/>
                </Routes>
            </Router>
        </RequestProvider>
        </AuthProvider>
    );
};

export default App;
