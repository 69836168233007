import React from 'react'
import styles from './Home.module.css'
import Layout from '../Layout'

export default function Home(): React.JSX.Element {

    return (
        <Layout>
            <p>Main page</p>
        </Layout>
    )
}